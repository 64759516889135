/**
 * @namespace components
 */

import React from 'react';
import PropTypes from 'prop-types';

import { miniGazelle } from '../../../helpers';

const getIsAriaHidden = ({ className, currentSlide, slideCount }) => {
    return className.includes('slick-next') ? currentSlide + 1 === slideCount :
           className.includes('slick-prev') ? currentSlide === 0 :
           false;
};

const AccessibleCarouselArrow = props => {
    const print = 1;
    const { i, currentSlide } = props;
    return (
        <button
            {...props}
            aria-hidden={getIsAriaHidden(props)}
            aria-label={
                currentSlide === i
                    ? `${miniGazelle.label('currentlyOnScreen', true)} ${miniGazelle.label('slide', true)}: ${print}`
                    : `${miniGazelle.label('slide', true)}: ${print}`
            }
        />
    );
};
export default AccessibleCarouselArrow;

AccessibleCarouselArrow.propTypes = {
    i: PropTypes.number.isRequired,
    currentSlide: PropTypes.number.isRequired,
};
